///// Importation /////

// MUI Button Template
import { Button } from "@mui/material";

// useNavigate from the Router
import { useNavigate } from "react-router-dom";

// The component to export
const LinkedBtn = (props) => {

    const navigate = useNavigate()

    // The onClick function
    const clickAction = () => {
        navigate(props.destination)
    }

    return (
        <Button
            className={props.className}
            sx={props.sx}
            variant={props.variant}
            onClick={clickAction}
            fullWidth={props.fullWidth}
            endIcon={props.endIcon}
        >
            {props.text}
        </Button>
    )
}

// Exportation of the Component
export default LinkedBtn