import Navbar from "../Components/Navbar"
// import Footer from "../Components/Footer"
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"
import { CardMedia, Container, Grid2 } from "@mui/material"
import FooterContent from "../Contents/Footer"
import ContactContent from "../Contents/Contact"
import './../Styles/Home.css'
import { useContext, useEffect } from "react"

// MUI Icon
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeContent from "../Contents/Home"
import { Link } from "react-router-dom"
import NavbarContent from "../Contents/Navbar"
import { CSVContext } from "../Contexts/CSVContext"
import { IPPubContext } from "../Contexts/IPPubContext"

// Personalised Contact Container
const ContactDiv = (props) => {
    return (
        <>
            <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                <Grid2 className="ToFullSize" container spacing={2}>
                    <Grid2 size={3} sx={{display: "flex", justifyContent: "right", alignItems: "right"}}>
                        {props.icon}
                    </Grid2>
                    <Grid2 size={9}>
                        <div className="font-0-6vw">{props.title}</div>
                        <div className="font-normal">{props.data}</div>
                    </Grid2>
                </Grid2>
            </Container>
        </>
    )
}

const Contact = () => {

    const csvData = useContext(CSVContext)
    const cityData = useContext(IPPubContext)

    useEffect(() => {
        document.title = ContactContent.pageTitle
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Navbar />
            <div
                style={{
                    minHeight: 'calc(100vh - 200px)',
                    marginTop: '150px',
                    display: "flex",
                    alignItems: 'center'
                }}
            >
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={1}></Grid2>
                        <Grid2 size={5} className="ToFullSize" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <motion.div
                                variants={fadeIn("right", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{
                                    width: "100%",
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    alt="Images"
                                    image={ContactContent.image}
                                    sx={{ width: "100%" }}
                                />
                            </motion.div>
                        </Grid2>
                        <Grid2 size={5} className="ToFullSize" sx={{ marginBottom: "25px", flexDirection: "column", display: "flex", justifyContent: "center" }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <motion.h1
                                    className="font-1-5vw"
                                    variants={fadeIn("left", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    style={{ width: '80%' }}
                                >
                                    {ContactContent['title']}
                                </motion.h1>
                                <motion.p
                                    className="font-normal"
                                    variants={fadeIn("left", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    style={{ width: '80%' }}
                                >
                                    {ContactContent['description']}
                                </motion.p>
                            </div>
                            <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                <Grid2 className="ToColumn" container spacing={2}>
                                    <Grid2 className="ToFullSize" size={12}>
                                        <motion.div
                                            variants={fadeIn("right", 0.2)}
                                            initial="hidden"
                                            whileInView={"show"}
                                            viewport={{once: false, amount: 0.7}}
                                        >
                                            <ContactDiv icon={<PhoneCallbackIcon className="font-2-5vw" />} title="Téléphone" data={FooterContent.contact.phone} />
                                        </motion.div>
                                    </Grid2>
                                    <Grid2 className="ToFullSize" size={12}>
                                        <motion.div
                                        variants={fadeIn("up", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        >
                                            <ContactDiv icon={<EmailIcon className="font-2-5vw" />} title="Email" data={FooterContent.contact.mail} />
                                        </motion.div>
                                    </Grid2>
                                    <Grid2 className="ToFullSize" size={12}>
                                        <motion.div
                                            variants={fadeIn("left", 0.2)}
                                            initial="hidden"
                                            whileInView={"show"}
                                            viewport={{once: false, amount: 0.7}}
                                        >
                                            {
                                                (cityData) ? ( (cityData !== null) ? (
                                                    (cityData.city.search("Toamasina") !== -1 || cityData.region.search("Toamasina") !== -1 || cityData.region.search("Atsinanana") !== -1) ? (
                                                        <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.newLocation.toamasina} />
                                                    ) : (
                                                        <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} />
                                                    )
                                                ) : (
                                                    <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} />
                                                )) : (
                                                    <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} 
                                                />)
                                            }
                                        </motion.div>
                                    </Grid2>
                                </Grid2>
                            </Container>
                        </Grid2>
                        <Grid2 size={1}></Grid2>
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                background: "linear-gradient(54deg, rgb(39 8 55), rgb(3 31 49))",
                left: "0",
                width: "100%",
                marginTop: "-2vh",
                padding: "10vh 0 5vh 0"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                    <Grid2 className="ToColumn" container spacing={2}>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{paddingLeft: "2vw"}}>
                                        <motion.div
                                                className="font-1-5vw"
                                                variants={fadeIn("right", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    alt="Logo"
                                                    image={FooterContent.logo}
                                                    sx={{ width: "10vw" }}
                                                    className='footerLogoDiv'
                                                />
                                            </motion.div>
                                        </Grid2>
                                        <Grid2 size={12} sx={{paddingLeft: "2vw", marginTop: "2vh"}} >
                                        <motion.p
                                                className="font-normal"
                                                variants={fadeIn("right", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                {FooterContent.description}
                                            </motion.p>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{ paddingLeft: "2vw" }} className="font-1-5vw">
                                            <motion.h1
                                                className="font-1-5vw"
                                                variants={fadeIn("up", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                {HomeContent.service.title}
                                            </motion.h1>
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <ul>
                                                {(csvData) ? (csvData.map((row, index) => {
                                                    return (<motion.li 
                                                        variants={fadeIn("up", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{once: false, amount: 0.7}}
                                                        key={index}>
                                                            <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={"/pages/"+row.Link}>{row.Name}</Link>
                                                        </motion.li>)
                                                })) : (<></>)}
                                            </ul>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{ paddingLeft: "2vw" }}>
                                            <motion.h1
                                                className="font-1-5vw"
                                                variants={fadeIn("left", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                Information
                                            </motion.h1>
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <ul>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.logo.destination} >Accueil</Link>
                                                </motion.li>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.contact.destination} >{NavbarContent.contact.text}</Link>
                                                </motion.li>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.apropos.destination} >{NavbarContent.apropos.text}</Link>
                                                </motion.li>
                                            </ul>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                left: "0",
                width: "100%",
                background: "black",
                padding: "2vh 5vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <motion.div
                    variants={fadeIn("right", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                    className='font-normal'
                >
                    {FooterContent.copyright}
                </motion.div>
                <motion.div
                    variants={fadeIn("left", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                    className='font-normal'
                >
                    Privacy policy - Terms of use
                </motion.div>
            </div>
        </>
    )
}

export default Contact