import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"
import { CardMedia, Container, Grid2 } from "@mui/material"
import './../Styles/Home.css'
import AproposContent from "../Contents/Apropos"
import { useState } from "react"
import React from "react"
import imageTeste from "../Images/other/client1.jpg"
import imageTeste2 from "../Images/other/client2.jpg"
import imageTeste3 from "../Images/other/client3.jpg"

const imageUtile = [
    imageTeste,
    imageTeste2,
    imageTeste3
]

const Apropos = () => {

    useState(() => {
        document.title = AproposContent.pageTitle
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Navbar />
            <Container maxWidth="xxl">
                <div
                    style={{
                        minHeight: 'calc(100vh - 200px)',
                        marginTop: '150px',
                        paddingBottom: "10vh",
                        display: "flex",
                        alignItems: 'center'
                    }}
                >
                    <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                        <Grid2 container spacing={2}>
                            <Grid2 size={12} className="ToFullSize" sx={{ marginBottom: "25px", flexDirection: "column", display: "flex", justifyContent: "center" }}>
                                <motion.h1
                                    className="font-2-5vw"
                                    variants={fadeIn("left", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    style={{ textAlign: 'center' }}
                                >
                                    {AproposContent['title']}
                                </motion.h1>
                            </Grid2>
                            {(AproposContent.description.map((row, index) => {
                                return (index%2 === 0) ? (
                                <React.Fragment key={index}>
                                    <Grid2 size={1}></Grid2>
                                    <Grid2 size={3} className="ToFullSize" sx={{paddingTop: "6vh", marginBottom: "10vh"}}>
                                        <motion.div
                                            variants={fadeIn("left", 0.2)}
                                            initial="hidden"
                                            whileInView={"show"}
                                            viewport={{once: false, amount: 0.7}}
                                        >
                                            <CardMedia
                                                component="img"
                                                alt="Image"
                                                image={imageUtile[index]}
                                                sx={{ width: "100%" }}
                                            />
                                        </motion.div>
                                    </Grid2>
                                    <Grid2 size={1}></Grid2>
                                    <Grid2 size={5} className="ToFullSize">
                                        <motion.p
                                            className="font-1-5vw"
                                            variants={fadeIn("left", 0.2)}
                                            initial="hidden"
                                            whileInView={"show"}
                                            viewport={{once: false, amount: 0.7}}
                                        >
                                            {row}
                                        </motion.p>
                                    </Grid2>
                                    <Grid2 size={2}></Grid2>
                                </React.Fragment>
                                ) : (
                                    <React.Fragment key={index}>
                                        <Grid2 size={3}></Grid2>
                                        <Grid2 size={5} className="ToFullSize">
                                            <motion.p
                                                className="font-1-5vw"
                                                variants={fadeIn("left", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}

                                            >
                                                {row}
                                            </motion.p>
                                        </Grid2>
                                        <Grid2 size={3} className="ToFullSize" sx={{paddingTop: "6vh", marginBottom: "10vh"}}>
                                            <motion.div
                                                variants={fadeIn("left", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    alt="Image"
                                                    image={imageUtile[index]}
                                                    sx={{ width: "100%" }}
                                                />
                                            </motion.div>
                                        </Grid2>
                                        <Grid2 size={1}></Grid2>
                                    </React.Fragment>
                                )
                                }))}
                            <Grid2 size={2} ></Grid2>
                            <Grid2 size={8} className="ToFullSize" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <motion.div
                                    variants={fadeIn("right", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    style={{
                                        width: "100%",
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        alt="Image"
                                        image={AproposContent['image']}
                                        sx={{ width: "100%" }}
                                    />
                                </motion.div>
                            </Grid2>
                            <Grid2 size={2} ></Grid2>
                        </Grid2>
                    </Container>
                </div>
            </Container>
            <Footer />
        </>
    )
}

export default Apropos