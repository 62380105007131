// Importation of the logo in the footer
import LogoResolys from './../Images/svg/Logo-rezolys.svg'

const FooterContent = {
    // The logo
    logo: LogoResolys,
    description: "Souhaitez-vous en savoir plus sur ce que Rezolys peut faire pour vous ? Contactez-nous dès aujourd'hui pour discuter de vos projets.",
    // Contact info
    contact: {
        phone: "+261 38 96 098 39",
        mail: "contact@rezolys.com",
        location: "Logement 449 Cité Ampefiloha 3ème étage, Antananarivo 101",
        newLocation: {
            tananarive: "Logement 449 Cité Ampefiloha 3ème étage, Antananarivo 101",
            toamasina: "Toamasina 501, Coming Soon"
        }
    },
    // The copyright text
    copyright: "Rezolys, Copyright 2024"
}

export default FooterContent
