import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"
import './../Styles/NoPage.css'

const NoPage = () => {
    const navigate = useNavigate()

    document.title = "Rezolys - page not found"

    const goHome = () => {
        navigate("/")
    }

    return (
        <>
            <div style={{ 
                position: "absolute",
                width: "100%",
                top: 0,
                left: 0,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                color: "white"
             }}>
                <div 
                className="theNotFound"
                style={{
                    display: "flex"
                }}>
                    <motion.div 
                        variants={fadeIn("right", 0.2)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{once: false, amount: 0.7}}
                        style={{
                            fontSize: "20vw",
                            fontWeight: 700,
                    }}>4</motion.div>
                    <motion.div 
                        variants={fadeIn("down", 0.2)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{once: false, amount: 0.7}}
                        style={{
                            fontSize: "20vw",
                            fontWeight: 700,
                    }}>0</motion.div>
                    <motion.div 
                        variants={fadeIn("left", 0.2)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{once: false, amount: 0.7}}
                        style={{
                            fontSize: "20vw",
                            fontWeight: 700,
                    }}>4</motion.div>
                </div>
                <motion.div
                className="textNotFound"
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}
                style={{
                    fontSize: "2vw",
                    marginTop: "-5vh"
                }}>The page you requested was not found.</motion.div>
                <motion.div 
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}
                style={{
                    marginTop: "1vh"
                }}>
                    <Button 
                    className="goHomeBtn"
                    sx={{
                        fontSize: "1.5vw",
                    }} onClick={goHome}>Page d'acceuil</Button>
                </motion.div>
            </div>
        </>
    )
}

export default NoPage