////// Library Importation ///////

// MUI Component
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CardMedia, Container, Grid2 } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// MUI Icon
import { ArrowUpward, Home } from '@mui/icons-material';

// Router Dom Link Navigate
import { useNavigate } from 'react-router-dom';

// Personalised Style Sheet
import "./../Styles/Navbar.css"

// Navbar Text content and Icon
import NavbarContent from '../Contents/Navbar';

// Personalised MUI Button + Link
import LinkedBtn from './LinkedBtn';

// React Hook
import { useContext, useEffect, useRef } from 'react';

// CSV Context Database
import { CSVContext } from '../Contexts/CSVContext';


// Logo Navigation bar Div + Link to Home
const LogoDiv = (props) => {
    const navigate = useNavigate()

    // When clicking the logo
    const clickLogo = () => {
        navigate(props.destination)
    }

    return (
        <CardMedia
            component="img"
            alt="Logo"
            image={props.logo}
            sx={{ width: "60%" }}
            className='LogoDiv'
            onClick={clickLogo}
        />
    )
}

// Navbar Component to export
const Navbar = () => {
    // Import the CSV Context
    const csvData = useContext(CSVContext)
    const navigate = useNavigate()

    // Set the Last scroll position as a Ref
    const lastScrollY = useRef(0)

    // Return to the top
    const returnTop = () => {
        window.scrollTo(0,0)
    }

    const navigateToHome = () => {
        navigate(NavbarContent.logo.destination)
    }

    // The change for the navbar while scrolling
    const fixNavBar = () => {
        try {
            const navElement = document.getElementById("myNavContainer")
            const btnArrow = document.getElementById("returnUp")
            const btnHome = document.getElementById("homeMin")
            const scrollY = window.scrollY
            const heightToFix = (window.innerHeight*0.03)
            const innerHeight = window.innerHeight
            const scrollHeight = document.body.scrollHeight
            const currentClass = navElement.className
            
            if (scrollY > heightToFix) {
                if (lastScrollY.current > scrollY) {
                    if (currentClass.search("fixNavbar") !== -1) {
                        navElement.className = navElement.className.replace(" fixNavbar", "")
                    }
                } else {
                    if (currentClass.search("fixNavbar") === -1) {
                        navElement.className += " fixNavbar"
                    }
                }
                btnArrow.style.display = "flex"
            } else {
                if (currentClass.search("fixNavbar") !== -1) {
                    navElement.className = navElement.className.replace(" fixNavbar", "")
                }
                btnArrow.style.display = "none"
            }

            // Btn in the mobile View
            if ((scrollHeight - scrollY) - innerHeight <= heightToFix) {
                if (btnArrow.className !== "upToUp") {
                    btnArrow.className = "upToUp"
                }
                if (btnHome.className !== "upToUp") {
                    btnHome.className = "upToUp"
                }
            } else {
                if (btnArrow.className === "upToUp") {
                    btnArrow.className = ""
                }
                if (btnHome.className === "upToUp") {
                    btnHome.className = ""
                }
            }

            lastScrollY.current = scrollY
        } catch (err) {}
    }
    
    // UseEffect react
    useEffect(() => {
        // Add the scroll listener to the windows
        window.addEventListener('scroll', fixNavBar)
    }, [])

    return (
        <>
            <Box id="myNavContainer" sx={{ width: '100%', position: 'absolute', top: '3vh', zIndex: "1000" }}>
                <Container maxWidth="xxl" sx={{ mt: 0, mb: 0 }}>
                    <Grid2 container spacing={2} className='navigationBar'
                    sx={{
                        borderRadius: "1rem",
                        maxHeight: "150px",
                        padding: "1vh 2vw"
                    }}>
                        <Grid2 size={2}>
                            <LogoDiv
                                logo={NavbarContent.logo.image}
                                destination={NavbarContent.logo.destination}
                            />
                        </Grid2>
                        <Grid2 size={10} sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                            <LinkedBtn
                                className='font-normal showing'
                                text = "Accueil"
                                destination = { NavbarContent.logo.destination }
                                sx={{justifyContent: 'flex-start', marginRight: "2vw", textTransform: "none !important"}}
                            />
                            <div className='dropdown2 showing'>
                                <Button className='font-normal navBtn' variant='text' sx={{textTransform: "none !important", marginRight: "2vw"}}>{NavbarContent.service.title}</Button>
                                <div className="dropdown-content2">
                                    {
                                        (csvData) ? csvData.map((row, index) => {
                                            return (
                                                <div 
                                                key={index}
                                                style={{
                                                    width: "100%"
                                                }}>
                                                    <LinkedBtn
                                                        fullWidth={true}
                                                        className='font-normal dropdownBtn2'
                                                        text = {row.Name}
                                                        destination = { "/pages/"+row.Link }
                                                        sx={{justifyContent: 'left', textAlign: 'left', textTransform: "none !important"}}
                                                    />
                                                </div>
                                            )
                                        }) : (<></>)
                                    }
                                </div>
                            </div>
                            <LinkedBtn
                                variant="outlined"
                                className='font-normal navBtn showing' 
                                sx={{ textTransform: "none !important", marginRight: "2vw" }}
                                text = { NavbarContent.apropos.text }
                                destination = { NavbarContent.apropos.destination }
                            />
                            <LinkedBtn 
                                variant='contained' 
                                className='font-normal navBtn'
                                text = {NavbarContent.contact.text}
                                destination = { NavbarContent.contact.destination }
                                sx={{ textTransform: "none !important" }}
                            />
                        </Grid2>
                        <Grid2 size={12} sx={{ mt: -1 }} className="disapear">
                            <hr style={{ margin: 0, padding: 0 }} />
                        </Grid2>
                        <Grid2 size={12} sx={{ mt: -1 ,display: "flex", alignItems: "center", justifyContent: "space-between" }} className="disapear">
                            <div className='dropdown'>
                                <Button className='font-normal navBtn' variant='text' sx={{textTransform: "none !important"}}>{NavbarContent.service.title}</Button>
                                <div className="dropdown-content">
                                    {
                                        (csvData) ? csvData.map((row, index) => {
                                            return (
                                                <div 
                                                key={index}
                                                style={{
                                                    width: "100%"
                                                }}>
                                                    <LinkedBtn
                                                        fullWidth={true}
                                                        className='font-normal dropdownBtn'
                                                        text = {row.Name}
                                                        destination = { "/pages/"+row.Link }
                                                        sx={{justifyContent: 'flex-start', textAlign: 'left', textTransform: "none !important"}}
                                                    />
                                                </div>
                                            )
                                        }) : (<></>)
                                    }
                                </div>
                            </div>
                            <LinkedBtn
                                variant="outlined"
                                className='font-normal navBtn' 
                                sx={{ textTransform: "none !important" }}
                                text = { NavbarContent.apropos.text }
                                destination = { NavbarContent.apropos.destination }
                            />
                        </Grid2>
                    </Grid2>
                </Container>
            </Box>
            <Box 
            className="fromNavBar"
            sx={{
                mb: '190px'
            }}>
                <Container maxWidth="xl" sx={{ mt: 0, mb: 0 }}>
                    <Grid2 id="reference" container spacing={2}></Grid2>
                </Container>
            </Box>
            <div id='returnUp'>
                <IconButton color='primary' onClick={returnTop}>
                    <ArrowUpward 
                    className='font-2-5vw'
                    sx={{
                        cursor: 'pointer !important'
                    }} />
                </IconButton>
            </div>
            <div id='homeMin'>
                <IconButton color='primary' onClick={navigateToHome}>
                    <Home 
                    className='font-2-5vw'
                    sx={{
                        cursor: 'pointer !important'
                    }} />
                </IconButton>
            </div>
        </>
    )
}

// Exportation of the Navbar component
export default Navbar