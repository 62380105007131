import logo from './../Images/svg/Icon-rezolys.svg'

const ContactContent = {
    // The title of the page: in the navigator tab
    pageTitle: "Rezolys: Nous contacter",
    // Title showing in the page
    title: "Nous contacter ?",
    // The image in the left
    image: logo,
    // The description about us
    description: "Pour toute demande d’information ou pour discuter de vos besoins spécifiques, n’hésitez pas à nous contacter; notre équipe se tient à votre disposition pour vous accompagner."
}

export default ContactContent