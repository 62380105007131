// Importation of the logo
import logo from './../Images/svg/Logo-rezolys.svg'

const AproposContent = {
    // The title of the page: in the navigator tab
    pageTitle: "Rezolys: A propos de nous",
    // Title showing in the page
    title: "A propos de nous",
    // The image in the left
    image: logo,
    // The description about us
    description: ["Chez Rezolys, nous nous positionnons comme votre partenaire de confiance pour l'intégration de solutions systèmes et réseaux, ainsi que pour la cybersécurité et la protection des infrastructures informatiques d'entreprise.",
        "Forts d'une expertise reconnue, nous nous engageons à offrir des solutions sur mesure qui répondent aux défis spécifiques de chaque client.",
        "Notre mission est de garantir la sécurité et l’efficacité de vos systèmes, en veillant à ce que votre entreprise puisse évoluer sereinement dans un environnement technologique en constante mutation."
    ]
}

export default AproposContent
