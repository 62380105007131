import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "./Pages/Home";
import NoPage from "./Pages/noPage";
import { Fragment, useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import View from "./Pages/View";
// import Apropos from "./Pages/Apropos";
import Apropos from "./Pages/AproposN";

import { CSVProvider } from "./Contexts/CSVContext";
import { IPPubProvider } from "./Contexts/IPPubContext";
import Contact from "./Pages/Contact";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {

  const handleCopy = (e) => {
    e.preventDefault();
  }

  useEffect(() => {
    document.addEventListener('copy', handleCopy);
    document.addEventListener('cut', handleCopy);
    document.addEventListener('paste', handleCopy);

    return () => {
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('cut', handleCopy);
      document.removeEventListener('paste', handleCopy);
    };
  }, [])

  return (
    <CSVProvider>
      <IPPubProvider>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Fragment>
            <BrowserRouter>
              <Routes>
                <Route path="/">
                  <Route index element={<Home />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="apropos" element={<Apropos />} />
                  <Route path="pages/:request" element={<View />} />
                  <Route path="*" element={<NoPage />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </Fragment>
        </ThemeProvider>
      </IPPubProvider>
    </CSVProvider>
  )
}

export default App;
