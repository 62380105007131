// The variant for the animation

export const fadeIn = (direction, delay, duration=0.5) => {
    const y = direction === 'up' ? 40 : direction === 'down' ? -40 : 0
    const x = direction === 'left' ? 40 : direction === 'right' ? -40 : 0
    if (direction === 'left') {
        return {
            hidden: {
                transform: `scale(0)`,
                opacity: 0,
                animationRange: 'entry 0'
            },
            show: {
                transform: `scale(1)`,
                opacity: 1,
                transition: {
                    type: 'tween',
                    duration: duration,
                    delay: delay,
                    ease: [0.25, 0.25, 0.25, 0.75]
                },
                animationRange: 'entry 0'
            }
        }
    } else {
        return {
            hidden: {
                x: x,
                y: y,
                opacity: 0,
                animationRange: 'entry 0'
            },
            show: {
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                    type: 'tween',
                    duration: duration,
                    delay: delay,
                    ease: [0.25, 0.25, 0.25, 0.75]
                },
                animationRange: 'entry 0'
            }
        }
    }
}